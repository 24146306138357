<template>
  <div>
    <div class="pt_zz" v-if="is_zz">
      <img src="../assets/fx.png" alt="" class="icon_zz" />
      <img
        src="../assets/close.png"
        alt=""
        class="icon_close"
        @click="is_zz = false"
      />
    </div>
    <div class="pt_btn_y">
      <div class="pt_btn" :class="{pt_mar:mar}" @click="pt">邀请好友</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rule: String,
    mar:Boolean
  },
  data() {
    return {
      is_zz: false,
    };
  },
  created() {
    this.$scaneMethod(
      location.href.split("#")[0],
      this.$route.query.id ? this.$route.query.id : this.$route.query.order_id
    );
  },
  methods: {
    pt() {
      this.is_zz = true;
      this.$scaneMethod(
        location.href.split("#")[0],
        this.$route.query.id ? this.$route.query.id : this.$route.query.order_id
      );
    },
  },
};
</script>
<style scoped>
.pt_btn {
  width: 327rem;
  height: 55rem;
  background: #ff5500;
  border-radius: 16rem;
  box-shadow: 0px 16rem 8rem 0px rgba(255, 128, 92, 0.08);
  font-size: 18rem;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25rem auto 15rem;
}
.pt_mar{
  margin: 0 auto;
}
.pt_zz {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.76);
  z-index: 999;
}

.pt_zz .icon_zz {
  width: 350rem;
  position: absolute;
  top: 0;
  right: 0;
}

.pt_zz .icon_close {
  width: 35rem;
  position: absolute;
  top: 400rem;
  left: 50%;
  transform: translateX(-50%);
}
</style>
