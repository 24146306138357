<template>
  <div class="pop" @click="hidePop">
    <div class="pop_m" @click.stop>
      <div class="pop_title">以下特殊日期不可用</div>
      <div class="pop_info">
        <div class="pop_info_item" v-for="(item,index) in arr" :key="index">{{item}}</div>
      </div>
      <div class="pop_btns">
        <div class="pop_btns_qs" @click="hidePop">知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    arr: Array,
  },
  data() {
    return {};
  },
  methods: {
    hidePop(){
        this.$emit('hidePop','关闭')
    },
  },
};
</script>
<style scoped>
.pop {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 99999;
}
.pop_info{
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    margin: auto;
    max-height: 110rem;
    overflow-y: auto;
}
.pop_info_item{
    width: 50%;
    font-size: 14rem;
    text-align: center;
    margin-bottom: 5rem;
}
.pop_m {
  width: 294rem;
  background: #ffffff;
  border-radius: 24rem;
  background-image: url(../assets/pop-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 140rem;
  left: 50%;
  transform: translateX(-50%);
}

.pop_title {
  height: 22.5rem;
  font-size: 16rem;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  color: #171746;
  line-height: 22.5rem;
  margin: 18rem 0;
}

.pop_info {
  font-size: 12rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #171746;
  line-height: 16.5rem;
  margin-bottom: 20rem;
}

.pop_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30rem;
}

.pop_btns > div {
  width: 94rem;
  height: 34rem;
  background: #ffb230;
  border-radius: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14rem;
  box-sizing: border-box;
}

.pop_btns_qs {
  background: none !important;
  color: #171746 !important;
  border: 1rem solid #171746 !important;
}

.pop_btns_qr {
  color: #fff;
  margin-left: 32rem;
}
</style>