import ticketCode from '../../components/ticketCode.vue'
import lablePop from '../../components/lablePop.vue' //弹窗
import ptBtnYq from '../../components/ptBtn.vue' //优惠券
export default {
    name: 'ptOrderInfo',
    components: {
        ticketCode,
        lablePop,
        ptBtnYq
    },
    data() {
        return {
            orderInfo: null,
            tickeShow: false, //显示二维码
            id: null,
            pop_show: false,
            timer: ['00', '00', '00', '00']
        }
    },
    created() {
        document.title = "订单详情";
        localStorage.removeItem('login_data');
        this.getOrderInfo();
    },
    methods: {
        // 支付
        payment(data) {
            let _this = this;
            if (typeof WeixinJSBridge == 'undefined') return;

            this.$UtilsApi.payment(data.id, {}).then(res => {
                let paymentData = JSON.parse(res.data.data.payment);
                WeixinJSBridge.invoke('getBrandWCPayRequest', {
                    "appId": _this.$appId, //公众号ID，由商户传入    
                    "timeStamp": paymentData.timestamp, //时间戳，自1970年以来的秒数    
                    "nonceStr": paymentData.nonceStr, //随机串    
                    "package": paymentData.package,
                    "signType": paymentData.signType, //微信签名方式：    
                    "paySign": paymentData.paySign //微信签名
                }, function(res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        _this.$router.push({
                            name: 'payState',
                            query: {
                                type: 'ok',
                                order_id: data.id,
                                price: data.price,
                                to: 1,
                                is_pt: 1
                            }
                        })

                    } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                        _this.$router.push({
                            name: 'payState',
                            query: {
                                type: 'no',
                                order_id: data.id,
                                price: data.price,
                                is_pt: 1,
                                to: 1
                            }
                        })

                    } else {
                        _this.$router.push({
                            name: 'payState',
                            query: {
                                type: 'qx',
                                order_id: data.id,
                                price: data.price,
                                is_pt: 1,
                                to: 1
                            }
                        })
                    }
                });
            })

        },
        hidePop(e) {
            this.pop_show = false;
        },
        //出示二维码
        playSY(id) {
            this.id = id;
            this.tickeShow = true;
        },
        //关闭二维码弹窗
        IsCode(e) {
            this.tickeShow = false;
        },
        //判断订单是否过期
        getTimerState(order_time) {
            var timestamp = Date.parse(new Date()) / 1000;
            return timestamp - order_time
        },
        //判断是否为当日订单
        isToday(timestamp) {
            // 将时间戳转换为Date对象
            let date = new Date(timestamp * 1000); // 时间戳以秒为单位，需乘以1000转换为毫秒

            // 获取当前日期
            let today = new Date();

            // 判断日期是否为今日
            if (
                date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()
            ) {
                return true;
            } else {
                return false;
            }
        },
        //更新时间函数
        upTimer() {
            this.orderInfo.Countdown = this.$countdown(this.orderInfo.order_time);
            setTimeout(() => {
                this.upTimer();
            }, 1000);
        },
        //获取订单详情
        getOrderInfo() {
            let _this = this;
            this.$UtilsApi.orderInfo(this.$route.query.id, {}).then(res => {
                if (res.data.code == 0) {
                    res.data.data['Countdown'] = '';
                    this.orderInfo = res.data.data;
                    this.upTimer(); //调用倒计时函数
                    function countdown(pay_time, work_time) {
                        // 计算结束时间戳（单位：毫秒）
                        var end_time = new Date(pay_time + work_time * 60 * 60 * 1000).getTime();

                        // 获取当前时间戳
                        var current_time = new Date().getTime();

                        // 计算剩余时间（单位：毫秒）
                        var remaining_time = end_time - current_time;

                        // 如果剩余时间小于等于0，表示已经过了结束时间
                        if (remaining_time <= 0) {
                            return [0, 0, 0, 0]; // 返回全为0的数组表示倒计时结束
                        }

                        // 计算时、分、秒、毫秒
                        var hours = Math.floor(remaining_time / (1000 * 60 * 60));
                        var minutes = Math.floor((remaining_time % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((remaining_time % (1000 * 60)) / 1000);
                        var milliseconds = remaining_time % 1000;

                        // 返回时、分、秒、毫秒的数组
                        _this.timer = [hours < 10 ? '0' + hours : hours, minutes < 10 ? '0' + minutes : minutes, seconds < 10 ? '0' + seconds : seconds, Math.floor(milliseconds / 100) < 10 ? '0' + Math.floor(milliseconds / 100) : Math.floor(milliseconds / 100)];
                        // 每隔10毫秒更新倒计时显示
                        setTimeout(function() {
                            countdown(_this.orderInfo.group_member[0].group_order.pay_time * 1000, _this.orderInfo.group_cnf.work_time);
                        }, 100);
                    };
                    if (_this.orderInfo.group_member) {
                        countdown(_this.orderInfo.group_member[0].group_order.pay_time * 1000, _this.orderInfo.group_cnf.work_time);
                    }
                    this.orderInfo.is_today = this.getTimerState(res.data.data.act_end_time) > 0 || (res.data.data.status == 1 && !this.isToday(res.data.data.order_time));
                } else {
                    _this.$router.push({
                        name: 'home',
                        query: _this.$route.query
                    });
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        // 根据时间戳转换日期
        getNewDate(timer) {
            var timestr = new Date(timer)
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            return year + "." + (month < 10 ? '0' + month : month) + "." + (date < 10 ? '0' + date : date);
        },
        // 根据时间戳转换日期
        getDate(time) {
            var timestr = new Date(parseInt(time) * 1000);
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            var hour = timestr.getHours();
            var minute = timestr.getMinutes();
            var second = timestr.getSeconds();
            return year + "-" + (month < 10 ? '0' + month : month) + "-" + (date < 10 ? '0' + date : date) + " " + (hour < 10 ? '0' + hour : hour) + ":" + (minute < 10 ? '0' + minute : minute) + ":" + (second < 10 ? '0' + second : second);
        }
    },
}